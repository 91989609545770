/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Header from "./Header"
import Footer from "./Footer"
import "bootstrap/dist/css/bootstrap.min.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div className="app">
        <link
          href="https://fonts.googleapis.com/css?family=Roboto&display=swap"
          rel="stylesheet"
        />
        <div className="appContainer">
          {children}
          <Footer />
          <div className="grid">
            <div className="row">
              <div className="col" />
              <div className="col" />
              <div className="col" />
              <div className="col d-none d-md-block" />
              <div className="col d-none d-md-block" />
              <div className="col d-none d-md-block" />
              <div className="col d-none d-lg-block" />
              <div className="col d-none d-lg-block" />
            </div>
          </div>
        </div>
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
