import React from "react"

import Layout from "../components/index/layout"
import SEO from "../components/index/seo"
import Plan from "../components/pricing/PlanBox"
import Header from "../components/index/Header"

const Pricing = () => (
  <Layout>
    <Header />
    <div className="nonHeroPageHero">
      <div className="pageTitle">
        <h1 id="pageTitle">Pricing</h1>
      </div>
    </div>
    <div className="Container">
      <SEO
        title="Pricing"
        keywords={[
          `SimpleReport`,
          `SimpleReport.ca`,
          `reporting`,
          `report`,
          `safety`,
          `OHS`,
          `OSHA`,
          `OHS-reporting`,
          `OSHA-reporting`,
          `invoices`,
          `invoicing`,
          `ERP`,
          `Occupational Health and Safety`,
          `EHS`,
          `Environmental Health and Safety`,
        ]}
      />
      <div className="plans">
        <div className="infoParagraph">
          SimpleReport will work with you to ensure that we have the right
          solution for your company. If you have any questions with regard to
          pricing, please contact us at{" "}
          <a href="mailto: info@simplereport.ca">info@simplereport.ca</a> or
          call us at <b>1(778)533-4592</b>. All future features and tools will
          be included in the plans below!
        </div>
        <h2>SimpleReport Plans</h2>
        <p>
          All of the plans below are listed on{" "}
          <i>
            <b>per user</b>
          </i>{" "}
          basis
        </p>
        <Plan
          plan="Small Business"
          price="4.99/month"
          users="1-50"
          reports="60"
          storage="5"
        />
        <Plan
          plan="Medium Enterprise"
          price="3.99/month"
          users="51-500"
          reports="unlimited"
          storage="5"
        />
        <Plan
          plan="Enterprise"
          price="custom"
          users="501+"
          reports="unlimited"
          storage="10"
        />
      </div>
    </div>
  </Layout>
)

export default Pricing
