import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col, ListGroup } from "react-bootstrap"
import logo from "../../images/gatsby-icon.svg"

const Footer = props => (
  <div id="pl-2 pr-2" id="footer">
    <div />
    <Row>
      <Col className="pt-3" sm={12} lg={3}>
        <img style={{ width: "150px", height: "50px" }} src={logo} />
      </Col>
      <Col className="justify-content-center pt-3" sm={12} lg={2}>
        <ListGroup id="footerList">
          <ListGroup.Item>
            <h6>Site Map</h6>
          </ListGroup.Item>
          <ListGroup.Item>
            <Link to="/">Home</Link>
          </ListGroup.Item>
          <ListGroup.Item>
            <Link to="/about-us">About Us</Link>
          </ListGroup.Item>
          <ListGroup.Item>
            <Link to="/services">Services</Link>
          </ListGroup.Item>
          <ListGroup.Item>
            <Link to="/contact-us">Contact Us</Link>
          </ListGroup.Item>
        </ListGroup>
      </Col>
    </Row>
    <Row>
      <Col className="d-flex justify-content-center pt-3 pb-3" sm={12} lg={12}>
        <i>&copy; Simple Report Inc., 2019</i>
      </Col>
    </Row>
    <div />
  </div>
)

export default Footer
