import React, { Component } from "react"
import { Link } from "gatsby"
import { Nav, NavDropdown, Navbar } from "react-bootstrap"
import "./layout.css"
import logo from "../../images/gatsby-icon.svg"

class NavigationBar extends Component {
  state = {
    color1: "inherit",
    color2: "white",
    color3: "white",
    windowWidth: "",
  }

  render() {
    return (
      <Navbar
        collapseOnSelect
        expand="md"
        bg=""
        variant="light"
        id={this.props.id}
        className="transparent row pr-0"
      >
        <div className="">
          <img
            src={logo}
            className="d-inline-block align-top pb-1 logo"
            alt="React Bootstrap logo"
          />
        </div>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav>
            <Link to="/" className="nav-link col" activeClassName="active">
              Home
            </Link>
            <Link
              to="/about-us"
              className="nav-link col"
              activeClassName="active"
            >
              About Us
            </Link>
            <Link
              to="/services"
              className="nav-link col"
              activeClassName="active"
            >
              Services
            </Link>
            <Link
              to="/contact-us"
              className="nav-link"
              activeClassName="active"
            >
              Contact Us
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

export default NavigationBar
