import React from "react"
import "./Plan.css"

const Plan = props => (
  <div className="columns">
    <ul className="price">
      <li className="header">{props.plan} Plan</li>
      <li className="grey">$ {props.price}</li>
      <li>{props.users} users</li>
      <li>{props.reports} reports/month</li>
      <li>{props.storage} GB storage</li>
    </ul>
  </div>
)

export default Plan
